.rmsc {
  --rmsc-main: #27292d !important;
  --rmsc-hover: #414246 !important;
  --rmsc-selected: #414246 !important;
  --rmsc-border: #27292d !important;
  --rmsc-gray: #aaa !important;
  --rmsc-bg: #27292d !important;
  --rmsc-p: 10px !important;
  --rmsc-radius: 4px !important;
  --rmsc-h: 38px !important;
}

.dropdown-content {
  background-color: #27292d;
  margin-top: 10px;
  border-radius: 6px;
}

.dropdown-content .search input {
  background-color: white !important;
  margin: auto !important;
  width: 90% !important;
  border-radius: 5px !important;
  align-items: center !important;
  margin-top: 8px !important;
  margin-bottom: 3px !important;
  display: flex;
  justify-content: center;
}
